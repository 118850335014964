import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Bangkok')

export function getDateString(day: Dayjs | Date): string {
  if (day instanceof Date) {
    day = dayjs(day)
  }

  return day.tz().format('DD/MM/YYYY')
}

export function getDatetimeString(day: Dayjs | Date): string {
  if (day instanceof Date) {
    day = dayjs(day)
  }

  return day.tz().format('YYYY-MM-DD HH:mm:ss')
}

export function getLocaleString(day: Dayjs | Date): string {
  if (day instanceof Date) {
    day = dayjs(day)
  }

  return day.tz().format('MMMM DD, YYYY')
}
