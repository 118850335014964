import { forwardRef } from 'react'
import { useTheme } from '@emotion/react'

import { ResponsiveCSSObject } from '@/styles/types'

type Props = {
  children?: React.ReactNode
  href: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  sx?: ResponsiveCSSObject
  layerStyle?: string
}

export const ExternalLink = forwardRef(function ExternalLink(
  { children, href, sx, onClick, layerStyle }: Props,
  ref: React.ForwardedRef<HTMLAnchorElement>,
) {
  const theme = useTheme()

  return (
    <a
      href={href}
      rel="external noreferrer"
      target="_blank"
      ref={ref}
      onClick={onClick}
      css={theme.mq({
        ...(layerStyle ? theme.layerStyles[layerStyle] : {}),
        ...sx,
      })}
    >
      {children}
    </a>
  )
})
