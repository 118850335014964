export function camelToSnakeCase(str: string) {
  return str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`)
}

export function camelToKababCase(str: string) {
  return str.replace(/[A-Z]/g, (letter: string) => `-${letter.toLowerCase()}`)
}

export function camelToTitleCase(str: string) {
  const _str = str.charAt(0).toUpperCase() + str.substr(1)

  return _str
    .replace(/[A-Z]/g, (txt: string) => {
      return ' ' + txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
    .substr(1)
}

export function getLocaleNumberString(
  value: number | string,
  fractionDigits = 2,
  locale = 'en-US',
): string {
  let _value = value

  if (typeof _value === 'string') {
    _value = parseFloat(_value)
  }

  if (locale === 'ja') {
    return `${value}`
  }

  return _value.toLocaleString(locale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })
}
