import { ResponsiveCSSObject } from '@/styles/types'

const textStyles: Dict<ResponsiveCSSObject> = {
  landingSectionHero: {
    fontWeight: '--fontWeights-semibold',
    marginBottom: '--space-10',
    fontFamily: '--fonts-heading',
    fontSize: {
      base: '--fontSizes-3xl',
      sm: '--fontSizes-4xl',
      md: '--fontSizes-5xl',
    },
  },
  landingSectionSubHero: {
    fontWeight: '--fontWeights-light',
    marginBottom: {
      base: '--space-4',
      xs: '--space-8',
      md: '--space-12',
    },
    fontFamily: '--fonts-body',
    fontSize: {
      base: '--fontSizes-xl',
      sm: '--fontSizes-2xl',
    },
  },
  landingSectionHeader: {
    fontWeight: '--fontWeights-semibold',
    marginBottom: '--space-10',
    fontFamily: '--fonts-heading',
    fontSize: {
      base: '--fontSizes-3xl',
      sm: '--fontSizes-4xl',
    },
  },
  landingSectionSubheader: {
    fontWeight: '--fontWeights-light',
    marginBottom: '--space-10',
    fontFamily: '--fonts-body',
    fontSize: {
      base: '--fontSizes-md',
      sm: '--fontSizes-2xl',
    },
  },
  landingSectionModalHeader: {
    fontWeight: '--fontWeights-medium',
    color: '--colors-primary-500',
    marginBottom: '--space-2',
    fontFamily: '--fonts-body',
    fontSize: {
      base: '--fontSizes-2xl',
      sm: '--fontSizes-3xl',
    },
  },
  landingSectionModalSubheader: {
    fontWeight: '--fontWeights-light',
    marginBottom: '0',
    fontFamily: '--fonts-body',
    fontSize: '--fontSizes-md',
  },
}

export default textStyles
