import { themeCSSPrefix } from '@/styles/constants'
import { Breakpoints } from '@/styles/types'

import { toVarRef } from './theme'

const sortByBreakpointValue = (a: any[], b: any[]) =>
  parseInt(a[1], 10) > parseInt(b[1], 10) ? 1 : -1

export const sortBps = (breakpoints: Breakpoints): Breakpoints =>
  Object.fromEntries(
    Object.entries(breakpoints).sort(sortByBreakpointValue),
  ) as Breakpoints

export function getBpKeys(breakpoints: Breakpoints) {
  const value = ['base', ...Object.keys(sortBps(breakpoints))]
  return new Set(value)
}

function createBreakpoint(bpValue: string) {
  return `@media (min-width: ${bpValue})`
}

function getValue(value: any) {
  return /^\-\-/.test(value) ? toVarRef(value, themeCSSPrefix) : value
}

export function isResponsive(breakpoints: Record<string, string>, test: Dict) {
  const keys = Object.keys(test)

  const bpKeys = getBpKeys(breakpoints)

  return keys.length > 0 && keys.every((key) => bpKeys.has(key))
}

export function createBreakpoints(breakpoints: Dict) {
  const mq = Object.values(sortBps(breakpoints)).map(createBreakpoint)

  function flatten(obj: any): Dict {
    if (typeof obj !== 'object' || obj == null) {
      return []
    }

    if (Array.isArray(obj)) {
      return obj.map(flatten)
    }

    const slots: Dict = {}
    const objects: Dict = {}
    const props: Dict = {}
    Object.entries(obj).forEach(([key, item]) => {
      // Check if value is an array, but skip if it looks like a selector.
      // key.indexOf('&') === 0

      if (Array.isArray(item) && key.charCodeAt(0) !== 38) {
        item.forEach((v, index) => {
          if (v == null) {
            // Do not create entries for undefined values as this will
            // generate empty media media quries
            return
          }

          if (index === 0) {
            props[key] = getValue(v)
          } else if (slots[mq[index]] === undefined) {
            slots[mq[index]] = { [key]: getValue(v) }
          } else {
            slots[mq[index]][key] = getValue(v)
          }
        })
      } else if (
        typeof item === 'object' &&
        item &&
        isResponsive(breakpoints, item)
      ) {
        Object.entries(item).forEach(([k, v]: [string, any]) => {
          if (v == null) {
            // Do not create entries for undefined values as this will
            // generate empty media media quries
            return
          }

          const bp = createBreakpoint(breakpoints[k])

          if (k === 'base') {
            props[key] = getValue(v)
          } else if (breakpoints[k] == null) {
            return
          } else if (slots[bp] === undefined) {
            slots[bp] = { [key]: getValue(v) }
          } else {
            slots[bp][key] = getValue(v)
          }
        })
      } else if (typeof item === 'object') {
        objects[key] = flatten(item)
      } else {
        props[key] = getValue(item)
      }
    })

    // Ensure that all slots and then child objects are pushed to the end
    mq.forEach((el) => {
      if (slots[el]) {
        props[el] = slots[el]
      }
    })

    Object.assign(props, objects)

    return props
  }

  return (value: Dict) => flatten(value)
}
