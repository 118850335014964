import { createBreakpoints } from '@/lib/utils'

import { Breakpoints } from '../types'

export const breakpoints: Breakpoints = {
  xs: '375px',
  sm: '768px',
  md: '1280px',
  lg: '1680px',
  xl: '1920px',
  '2xl': '2560px',
}

const mq = createBreakpoints(breakpoints)

const style = {
  breakpoints,
  mq,
}

export default style
