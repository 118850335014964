import { Fragment } from 'react'
import { AppProps } from 'next/app'
import { DefaultSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { NextComponentType } from 'next'

import { ProductionScripts } from '@/components/Scripts'
import ThemeProvider from '@/styles/ThemeProvider'

import { useInitialScroll } from '@/lib/hooks/useInitialScroll'
import env from '@/lib/env'
import { useGTMPageview, UTMContextProvider } from '@/lib/gtm'

const DEFAULT_META: PageMeta = {
  title: 'Consent Wow',
  description:
    'จัดการ บันทึกคำขอความยินยอมและสร้าง แบบฟอร์มขอความยินยอมของลูกค้าหรือผู้ใช้งานของคุณให้สอดคล้อง PDPA ได้ง่าย ๆ  ในที่เดียว',
  og: {
    title: 'Consent Wow',
    description:
      'จัดการ บันทึกคำขอความยินยอมและสร้าง แบบฟอร์มขอความยินยอมของลูกค้าหรือผู้ใช้งานของคุณให้สอดคล้อง PDPA ได้ง่าย ๆ  ในที่เดียว',
    image: {
      url: `${env.APP_URL}/images/og.jpg`,
    },
  },
}

type PageComponentType = {
  getLayout?: (page: React.ReactNode) => React.ReactNode
} & NextComponentType

function MyApp(props: AppProps): React.ReactElement {
  const { pageProps } = props
  const Component: PageComponentType = props.Component
  const getLayout = Component.getLayout || ((page) => page)

  const router = useRouter()

  useInitialScroll()
  useGTMPageview()

  return (
    <Fragment>
      <ProductionScripts />
      <DefaultSeo
        title={DEFAULT_META.title}
        titleTemplate="%s | Consent Wow"
        description={DEFAULT_META.description}
        canonical={`${env.APP_URL}${router.asPath}`}
        openGraph={{
          type: 'website',
          url: `${env.APP_URL}${router.asPath}`,
          title: DEFAULT_META.og.title,
          description: DEFAULT_META.og.description,
          site_name: 'Consent Wow',
          images: [
            {
              url: DEFAULT_META.og.image.url,
              width: 1200,
              height: 630,
              alt: DEFAULT_META.og.title,
            },
          ],
        }}
      />
      <UTMContextProvider>
        <ThemeProvider>{getLayout(<Component {...pageProps} />)}</ThemeProvider>
      </UTMContextProvider>
    </Fragment>
  )
}

export default MyApp
