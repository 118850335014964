const colors = {
  primary: {
    50: '#EDF7F6',
    100: '#DCEFED',
    200: '#96F0E0',
    250: '#79C3BA',
    300: '#6FEAD4',
    400: '#4BAFA2',
    500: '#1E9B8B',
    600: '#187C6F',
    700: '#13846F',
    800: '#10715F',
    900: '#0D5E4F',
    1000: '#083830',
  },
  secondary: {
    300: '#FAC3AA',
    500: '#F79A71',
    600: '#C57B5A',
  },
  tertiary: {
    900: '#203E4A',
  },
  gray: {
    0: '#ffffff',
    25: '#f4f4f4',
    50: '#f8f8f8',
    100: '#f6f6f6',
    200: '#ebebeb',
    300: '#e8e8e8',
    310: '#e6e6e6',
    380: '#c1c1c1',
    400: '#b2b2b2',
    500: '#a3a3a3',
    600: '#888888',
    680: '#7f7f7f',
    700: '#474747',
    800: '#3d3d3d',
    900: '#2b2b2b',
    920: '#252525',
    950: '#1a1a1a',
    1000: '#000000',
  },
}

export default colors
