import { extend } from '@/lib/utils/theme'

import foundations from './foundations'
import layerStyles from './styles/layerStyles'
import textStyles from './styles/textStyles'
import colors from './styles/colors'
import style from './styles/style'

const theme = {
  colors,
  layerStyles,
  textStyles,
  ...style,
}

export const defaultTheme = extend(foundations, theme)

type _Theme = typeof foundations & typeof theme

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends _Theme {}
}

export default defaultTheme
