import { Link } from './types'

export const LANDING: Link = {
  pathname: '/',
  hash: 'top',
  priority: 1,
}

export const INFO: Link = {
  pathname: '/',
  hash: 'info',
}

export const WHY_US: Link = {
  pathname: '/',
  hash: 'why-us',
}

export const GET_STARTED: Link = {
  pathname: '/',
  hash: 'get-started',
}

export const PRICE: Link = {
  pathname: '/',
  hash: 'price',
}

export const FAQ: Link = {
  pathname: '/',
  hash: 'faqs',
}

export const QUOTATION_FORM: Link = {
  pathname: '/',
  hash: 'quotation-form',
}

export const NOTICE_FORM: Link = {
  pathname: '/',
  hash: 'notice-form',
}

export const OTHER_PRODUCTS: Link = {
  pathname: '/',
  hash: 'other-products',
}

export const TEL: Link = {
  pathname: 'tel:020245560',
}

export const EMAIL: Link = {
  pathname: 'mailto:sales@ndatathoth.com',
}
