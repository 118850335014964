import { extendTheme } from '@chakra-ui/react'

import colors from './styles/colors'
import { breakpoints } from './styles/style'
import components from './styles/components'

const overrides = {
  colors,
  components,
  breakpoints,
}

const chakraTheme = extendTheme(overrides)

export default chakraTheme
