import type { ComponentStyleConfig } from '@chakra-ui/theme'

export const Switch: ComponentStyleConfig = {
  baseStyle: {
    thumb: {
      boxSize: '32px',
      _checked: {
        transform: 'translateX(40px)',
      },
    },
    track: {
      w: '70px',
      h: '45px',
      px: '10px',
      alignItems: 'center',
      boxShadow: 'none !important',
      _checked: {
        bg: '#2BCF86',
      },
    },
  },
}
