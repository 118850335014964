import { ReactNode, ReactElement } from 'react'
import { ChakraProvider } from '@chakra-ui/react'
import { ThemeProvider } from '@emotion/react'

import theme from './theme'
import chakraTheme from './chakraTheme'
import GlobalStyle from './GlobalStyle'

interface ThemeProviderProps {
  children: ReactNode
}

function AppThemeProvider(props: ThemeProviderProps): ReactElement {
  return (
    <ChakraProvider resetCSS theme={chakraTheme}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {props.children}
      </ThemeProvider>
    </ChakraProvider>
  )
}

export default AppThemeProvider
