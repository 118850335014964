import { forwardRef, ForwardedRef, useImperativeHandle } from 'react'
import { Link as ReactScrollLink } from 'react-scroll'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import { ExternalLink } from '@/components/ExternalLink'
import { ResponsiveCSSObject } from '@/styles/types'
import * as ROUTES from '@/routes'
import type { Link as LinkType } from '@/routes'

export type ScrollOption = {
  spy?: boolean
  smooth?: boolean
  hashSpy?: boolean
  duration?: number
  offset?: number
  activeClass?: string
}

type CommonProps = {
  sx?: ResponsiveCSSObject
  children?: React.ReactNode
  onClick?: () => void
  scrollOption?: ScrollOption
  passHref?: boolean
}

export type URL = RequireAtLeastOne<{
  href: LinkType | string
  to: string
}>

export const SCROLL_OPTION: ScrollOption = {
  spy: false,
  smooth: true,
  hashSpy: true,
  duration: 500,
  offset: -60,
}

function LinkComponent(
  {
    to,
    href,
    scrollOption,
    passHref = true,
    sx,
    onClick,
    ...rest
  }: CommonProps & URL,
  ref: ForwardedRef<unknown>,
): React.ReactElement {
  useImperativeHandle(ref, () => ({}))

  const { pathname } = useRouter()

  const _url =
    typeof href === 'string'
      ? {
          pathname: href,
        }
      : href
      ? href
      : to
      ? (ROUTES as Dict<LinkType>)[to]
      : null

  if (!_url?.pathname) {
    throw new Error('Link href/to must not be empty.')
  }

  if (
    (_url.pathname === pathname || _url.pathname === 'currentPath') &&
    _url.hash
  ) {
    return (
      <ReactScrollLink
        {...SCROLL_OPTION}
        {...scrollOption}
        {...rest}
        onClick={onClick}
        to={_url.hash}
        href={_url.hash === 'top' ? '#' : `#${_url.hash}`}
      />
    )
  }

  if (_url.isExternal) {
    return (
      <ExternalLink sx={sx} onClick={onClick} {...rest} href={_url.pathname} />
    )
  }

  return <NextLink href={_url} passHref={passHref} {...rest} />
}

export const Link = forwardRef(LinkComponent)
