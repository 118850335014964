import { Link } from './types'

import env from '@/lib/env'

export const HELP: Link = {
  pathname: `${env.PLATFORM_URL}/starter-guide`,
  isExternal: true,
}

export const FACEBOOK: Link = {
  pathname: 'https://www.facebook.com/pdpapro',
  isExternal: true,
}

export const APP: Link = {
  pathname: `${env.PLATFORM_URL}`,
  isExternal: true,
}

export const APP_SIGN_IN: Link = {
  pathname: `${env.PLATFORM_URL}`,
  isExternal: true,
}

export const APP_SIGN_UP: Link = {
  pathname: `${env.PLATFORM_URL}/sign-up`,
  isExternal: true,
}

export const COOKIE_WOW: Link = {
  pathname: 'https://cookiewow.com',
  isExternal: true,
}

export const PDPA_PRO: Link = {
  pathname: 'https://pdpa.pro',
  isExternal: true,
}

export const PROKIT: Link = {
  pathname: 'https://pdpa.pro/prokit',
  isExternal: true,
}

export const LEARN_PDPA: Link = {
  pathname: 'https://pdpaform.com',
  isExternal: true,
}

export const PDPA_FORM: Link = {
  pathname: 'https://learnpdpa.com',
  isExternal: true,
}
