import sizes from '../foundations/sizes'
import { ResponsiveCSSObject } from '@/styles/types'

const layerStyles: Dict<ResponsiveCSSObject> = {
  actionable: {
    opacity: 1,
    cursor: 'pointer',
    transition: 'opacity 150ms',
    '&:hover': {
      opacity: 0.8,
    },
    '&:focus': {
      opacity: 0.65,
    },
    '&:active': {
      opacity: 0.5,
    },
  },
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  landingSectionContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    color: '--colors-gray-900',
    textAlign: 'center',
    width: '--sizes-full',
    minWidth: '320px',
    maxWidth: {
      base: '--sizes-full',
      sm: '--sizes-container-sm',
      md: '--sizes-container-lg',
    },
    margin: '0 auto',
    paddingLeft: {
      base: '--space-6',
      md: 0,
      lg: 0,
    },
    paddingRight: {
      base: '--space-6',
      md: 0,
      lg: 0,
    },
    paddingTop: {
      base: '--space-14',
      sm: '--space-18',
      md: '--space-24',
      lg: '--space-27',
    },
    paddingBottom: {
      base: '--space-14',
      sm: '--space-18',
      md: '--space-24',
      lg: '--space-27',
    },
  },
  fullViewPortHeight: {
    minHeight: {
      base: `calc(100vh - ${sizes.nav.sm})`,
      md: `calc(100vh - ${sizes.nav.md})`,
    },
  },
}

export default layerStyles
