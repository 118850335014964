import { Link } from './types'

export const TERMS_OF_USE: Link = {
  pathname: '/terms-of-use',
  isExternal: true,
}

export const PRIVACY_POLICY: Link = {
  pathname: '/privacy-policy',
  isExternal: true,
}
