import { ReactElement } from 'react'
import { Global, css } from '@emotion/react'
import emotionReset from 'emotion-reset'

import { objectToVar } from '@/lib/utils'
import theme from './theme'
import { themeCSSPrefix } from './constants'

function GlobalStyle(): ReactElement {
  return (
    <>
      <Global
        // reset
        styles={css`
          ${emotionReset}

          heading,
          body,
          input,
          textarea,
          table,
          th,
          td,
          button {
            font-family: ${theme.fonts.body};
            font-weight: ${theme.fontWeights.body};
            color: ${theme.colors.gray[900]};
            line-height: 1.5;
            bordw-color: ${theme.colors.gray[300]};
          }

          * {
            box-sizing: border-box;
            outline: none;
            font-family: inherit;
            font-size: inherit;
            border-color: inherit;
          }

          a {
            text-decoration: none;
            color: inherit;
          }
        `}
      />
      <Global
        // theme
        styles={css`
          :root {
            ${objectToVar(theme, themeCSSPrefix)}
          }
        `}
      />
      <Global
        // selection
        styles={css`
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          textarea:-webkit-autofill,
          textarea:-webkit-autofill:hover,
          textarea:-webkit-autofill:focus,
          select:-webkit-autofill,
          select:-webkit-autofill:hover,
          select:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0 30px #fff inset !important;
          }
        `}
      />
      <Global
        // layout
        styles={theme.mq({
          '#__next': {
            minHeight: '100vh',
            maxWidth: '100%',
          },

          main: {
            overflowX: 'hidden',
            minHeight: {
              base: `calc(100vh - ${theme.sizes.nav.sm})`,
              md: `calc(100vh - ${theme.sizes.nav.md})`,
            },
          },
        })}
      />
    </>
  )
}

export default GlobalStyle
