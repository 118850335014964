import { camelToKababCase } from './format'

export function isDecimal(value: any) {
  return !Number.isInteger(parseFloat(value.toString()))
}

function replaceWhiteSpace(value: string, replaceValue = '-') {
  return value.replace(/\s+/g, replaceValue)
}

function escape(value: string | number) {
  const valueStr = replaceWhiteSpace(value.toString())
  if (valueStr.includes('\\.')) return value
  return isDecimal(value) ? valueStr.replace('.', `_`) : value
}

export function addPrefix(value: string, prefix = '') {
  return [prefix, escape(value)].filter(Boolean).join('-')
}

export function toVarRef(name: string, prefix = '') {
  const nameSplitted = name.split(/^\-\-/)

  return `var(--${prefix}-${escape(nameSplitted[1])})`
}

export function toVar(value: string, prefix = '') {
  return `--${addPrefix(value, prefix)}`
}

export function objectToVar(source: Dict, prefix = ''): string {
  return Object.entries(source)
    .map(([key, value]) => {
      if (
        typeof value == 'function' ||
        key === 'layerStyles' ||
        key === 'textStyles'
      ) {
        return ''
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        return objectToVar(value, `${prefix}-${key}`)
      } else {
        return `${toVar(camelToKababCase(key), prefix)}: ${value};`
      }
    })
    .join('\r\n')
}

export function extend(source: Dict, object: Dict) {
  const result: Dict = { ...source }

  Object.entries(object).forEach(([key, value]) => {
    if (result[key]) {
      Object.assign(result[key], value)
    } else {
      result[key] = value
    }
  })

  return result
}
